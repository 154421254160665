@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

html,
html:focus-within {
  scroll-behavior: smooth;
}

body {
  font-size: 1.2rem;
  letter-spacing: 1px;
  background: #fafafa;
  cursor: context-menu;
}

.user {
  h1,
  h2,
  h3,
  h4,
  ul,
  li,
  a,
  p,
  input,
  label,
  button,
  div,
  footer {
    font-family: "Quicksand", sans-serif;
    color: #444;
  }

  h2 {
    font-weight: bold;
    font-size: 3em;
  }

  h3 {
    font-weight: bold;
    font-size: 1.6em;
    color: #999;
  }

  p {
    margin-bottom: 0;
  }

  ul {
    list-style-type: none;
  }

  a,
  a:hover {
    text-decoration: none;
    color: inherit;
  }

  .dropdown-item:active {
    background-color: inherit;
  }

  .btn:hover,
  a:hover {
    color: #fff;
  }

  #searchBox {
    padding: 20px 0 20px 0;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #fafafa;
  }

  .card-wrapper {
    &:hover {
      box-shadow: 0 0 1rem 0.1rem rgba(13, 110, 253, 0.75);
    }
    box-shadow: 0 5px 10px rgba(94, 45, 216, 0.3);
    transition: box-shadow 0.4s ease-in-out;
    position: relative;
    cursor: pointer;
  }

  .card-img {
    height: auto;
    width: 100%;
  }

  .card-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffe6ee;
    opacity: 0;
  }

  @media (min-width: 768px) {
    .customCollapse {
      height: auto;
    }
    .showCustomCollapse {
      height: auto;
    }
  }

  @media (max-width: 768px) {
    .customCollapse {
      transition: height 0.35s ease;
      overflow: hidden;
      height: 0;
    }
    .showCustomCollapse {
      height: 135px !important;
    }
  }
}

.text-blue {
  color: #b4abfc !important;
}

.text-pink {
  color: #ffb3f2 !important;
}

.text-soft-pink {
  color: #ffe6ee !important;
}

.bg-blue {
  background-color: #b4abfc !important;
}

.bg-pink {
  background-color: #ffcad4 !important;
}

.bg-soft-pink {
  background-color: #ffe6ee !important;
}

.outline-soft-pink {
  border: 3px solid #ffe6ee !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

/* Hanging transition */
@keyframes bounce {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, -40px);
  }
}

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translate(0px, 0px);
  }
  to {
    -webkit-transform: translate(0px, -40px);
  }
}

@-moz-keyframes bounce {
  from {
    -moz-transform: translate(0px, 0px);
  }
  to {
    -moz-transform: translate(0px, -40px);
  }
}

.bounce {
  animation-name: bounce;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;

  -webkit-animation-name: bounce;
  -webkit-animation-duration: 2s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;

  -moz-animation-name: bounce;
  -moz-animation-duration: 2s;
  -moz-animation-direction: alternate;
  -moz-animation-timing-function: linear;
  -moz-animation-delay: 0s;
  -moz-animation-iteration-count: infinite;
}
